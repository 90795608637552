import React, { useState, useEffect} from 'react';
import './App.css'; // import your CSS file for styling
import './BookPromo.css';
import SiteContent from './SiteContent';



const App = () => {
  const [videoEnded, setVideoEnded] = useState(false);
  const [showForm, setShowForm] = useState(true);
  
  

  // useEffect hook to handle video end event
  useEffect(() => {
    const handleVideoEnd = () => {
      setVideoEnded(true);
      };

    const videoElement = document.getElementById('video');
    videoElement.addEventListener('ended', handleVideoEnd);

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

     

    return (
    <div className="App">
      {!videoEnded && (
        <div className="video-container">
          <video id="video" poster='thumbnail_lightning.jpg' autoPlay muted>
            <source src="lightning.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      {videoEnded && (
        
         <SiteContent showForm={showForm} setShowForm={setShowForm} />
        
      )}
    </div>
  );
};

export default App;
