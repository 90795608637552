import React from 'react'
import './BookPromo.css';

function ContactForm({ showForm, setShowForm }) {
 
  
   

    return showForm ? (
     
  
      <form className="book-promo__form" method="POST" name="contact" data-netlify="true" netlify-honeypot="phone-field">
    <input type="hidden" name="form-name" value="contact" />
    <label htmlFor="name">Naam:</label>
    <input
      type="text"
      id="name"
      name="name"
      required
      maxLength="100"
    />
    <label htmlFor="lastname">Telefoonnummer:</label>
    <input
      type="text"
      id="lastname"
      name="lastname"
          maxLength="15"
    />
    <label htmlFor="email">Email:</label>
    <input
      type="email"
      id="email"
      name="email"
      required
    />
    <label htmlFor="message">Message:</label>
    <textarea
      id="message"
      name="message"
      required
    />
    <div className="checkbox-and-label">
    <label htmlFor="GDPRChecked">
      <input
        type="checkbox"
        id="GDPRChecked"
        name="GDPRChecked"
        required
      />
      Ja, mijn gegevens mogen worden gebruikt voor de goede afhandeling van mijn vraag of opmerking. Zij worden in geen geval verder gestuurd naar een derde partij.
    </label>
    
    
    
    </div>
    
    <button type="submit">Submit</button>
    <button type="button" onClick={() => setShowForm(false)}>Close</button>
  </form>
  
    ) : (
      <>
        <button className="book-promo__contact-button" onClick={() => setShowForm(true)}>Contact</button>
        
      </>
    );
  };

  export default ContactForm;
