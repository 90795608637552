 // SiteContent.js
 import React, { useEffect, useState } from 'react';
import './BookPromo.css';
import ContactForm from './ContactForm';
import book1 from './elet.jpg';



const SiteContent = ({ showForm, setShowForm }) => {
  

  const [encryptedEmail, setEncryptedEmail] = useState('');
  const [encryptedPhoneNumber, setEncryptedPhoneNumber] = useState('');

  useEffect(() => {
      // Example email and phone number
      const exampleEmail = 'deleted';
  const examplePhoneNumber = '0488779007';

      // Encryption key (XOR key)
      const xorKey = 42;

      // Encrypt the email and phone number using XOR encryption
      const encryptedEmail = xorEncrypt(exampleEmail, xorKey);
      const encryptedPhoneNumber = xorEncrypt(examplePhoneNumber, xorKey);

      // Set the encrypted email and phone number states
      setEncryptedEmail(encryptedEmail);
      setEncryptedPhoneNumber(encryptedPhoneNumber);
  }, []);

  // XOR encryption function
  function xorEncrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key);
      }
      return result;
  }

  // XOR decryption function (since XOR is symmetric, decryption is the same as encryption)
  function xorDecrypt(encryptedText, key) {
      return xorEncrypt(encryptedText, key);
  }

      return (
        
    
        <div className="book-promo">
      
        <div className="book-promo__content">
          <div className="book-promo__text">
          
    
          
    
    
          <h1>Elektricien in Antwerpen nodig?</h1>
  
          <div>
                <h2>ElektroAA - Uw Partner in Elektriciteit</h2>
                <p>Wilt u de elektriciteit in uw woning vernieuwen? Of werd uw elektrische installatie recent afgekeurd? Dan kunt u bij ElektroAA terecht. Wij luisteren aandachtig naar uw probleem en zullen in onderling overleg met u aan de slag gaan om uw elektrische installatie op orde te brengen.</p>
                <h2>Regio's waar wij actief zijn:</h2>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <ul>
                            <li>Antwerpen</li>
                            <li>Berchem</li>
                            <li>Borgerhout</li>
                            <li>Deurne</li>
                            <li>Hoboken</li>
                        </ul>
                    </div>
                    <div style={{ width: '50%' }}>
                        <ul>
                            <li>Linkeroever</li>
                            <li>Merksem</li>
                            <li>Mortsel</li>
                            <li>Wilrijk</li>
                            <li>Wijnegem</li>
                        </ul>
                    </div>
                </div>
                <p>Ook voor kleine klussen zoals een deurbel plaatsen of een nieuwe lamp installeren kunt u bij ons terecht.</p>
                <p>Neem vandaag nog contact met ons op en boek een <strong>gratis plaatsbezoek</strong>. Na afloop ontvangt u een vrijblijvende offerte.</p>
                <p>
                    Voor vragen kunt u ons bereiken via het contact formulier
                    <br />
                    Of bel ons op: <strong>{xorDecrypt(encryptedPhoneNumber, 42)}</strong>
                </p>
            </div>
    
    
  
          </div>
          <div className="book-promo__image">
            <img src={book1} alt="Book Cover" />
          </div>
        </div>
                <ContactForm showForm={showForm} setShowForm={setShowForm} />
                
      </div>
      );
};

export default SiteContent;
